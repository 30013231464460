import React from 'react'
import Layout from '../components/layout'
import AbuseHero from '../components/abuse/abuse-hero'
import PolicyDetails from '../components/abuse/abuse-content'
import SEO from '../components/seo'

const Abuse = () => {
  return (
    <Layout isTallFooter>
      <SEO
        title='Abuse and Copyright Violation Reporting'
        pageType='features'
        flowType='none'
      />
      <AbuseHero />
      <PolicyDetails />
    </Layout>
  )
}

export default Abuse

import React from 'react'
import HeroBg from '../../../images/features-hero.jpg'
import Hero from './abuse-hero.styles'

const AbuseHero = () => (
  <Hero background={HeroBg}>
    <Hero.contentContainer>
      <Hero.title>Report Abuse</Hero.title>
      <Hero.subtitle>Abuse & Copyright Violation Reporting</Hero.subtitle>
    </Hero.contentContainer>
  </Hero>
)

export default AbuseHero
